<template>
  <OnboardingStep
    :title="$t('onboarding.backstockIntroView.title')"
    :next-text="$t('onboarding.backstockIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.backstockIntroView.paragraphs">
      <template #help-link>
        <RaiLink :href="$t('onboarding.backstockIntroView.helpLink.href')">
          {{ $t("onboarding.backstockIntroView.helpLink.text") }}
        </RaiLink>
      </template>
    </I18nArray>
    <div class="backstock-video">
      <video :width="videoWidth" height="400" controls>
        <source src="videos/backstock.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";

export default {
  name: "BackstockIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
  computed: {
    videoWidth() {
      return this.$vuetify.breakpoint.mobile ? "100%" : "500";
    },
  },
};
</script>

<style lang="scss" scoped>
.backstock-video {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
</style>
